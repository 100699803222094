<template>
  <div>
    <Hero :visible.sync="visible" :show-cutout="false" class="pb-56 pt-28">
      <b-container class="mt-20">
        <b-row>
          <b-col lg="10" offset-lg="1" class="text-center">
            <h1
              class="text-4xl text-md-6xl text-lg-8xl text-white font-weight-black font-realist tracking-tight leading-none mb-md-8 mb-4"
            >
              Privacy Policy
            </h1>
          </b-col>
        </b-row>
      </b-container>
    </Hero>
    <main>
      <b-container class="mt-n32 mb-24">
        <b-row>
          <b-col md="10" offset-md="1" lg="8" offset-lg="2">
            <div class="bg-white rounded shadow p-12">
              <p><strong>ACV AUCTIONS PRIVACY POLICY</strong></p>
              <h4>1.&nbsp; About this Privacy Policy</h4>
              <p>
                Thank you for using ACV Auctions!&nbsp; Here at ACV Auctions
                (collectively ACV Auctions Inc., our affiliates and
                subsidiaries, hereinafter referred to as &ldquo;ACV
                Auctions,&rdquo; &ldquo;us,&rdquo; &ldquo;we,&rdquo;
                &ldquo;our,&rdquo; or &ldquo;Company&rdquo;) privacy is very
                important to us.&nbsp; This ACV Auctions Privacy Policy (this
                &ldquo;Privacy Policy&rdquo;) describes how ACV Auctions
                collects, uses, and handles your information when you use our
                website, app, software, and other services
                (&ldquo;Services&rdquo;).&nbsp; By submitting personal
                information to ACV Auctions, by conducting business with ACV
                Auctions or by using our Services, you (hereinafter referred to
                as &ldquo;you,&rdquo; or &ldquo;Customer&rdquo;) agree to the
                terms of this Privacy Policy and expressly consent to the
                collection, processing, use and disclosure of your personal
                information for the purposes set out in this Privacy
                Policy.<span style="text-decoration: line-through"> </span>
              </p>
              <p>
                This Privacy Policy does not apply to persons outside the United
                States, including in the EEA/ European Union.&nbsp; ACV Auctions
                does not intend to offer goods and services to, or collect
                personal information from, persons outside the United
                States.&nbsp; If you are a California resident, you may have
                additional privacy rights as set forth below.
              </p>
              <p>
                At times, ACV handles personal information collected by another
                company for the sole purpose of performing a service for that
                company, such as scheduling and performing inspections of
                off-lease vehicles. We refer to these companies as our
                &ldquo;Clients,&rdquo; and ACV processes this information only
                at the Client&rsquo;s direction.&nbsp; In these instances, we
                follow the Client&rsquo;s privacy terms.
              </p>
              <p>
                This Privacy Policy does not apply to other companies&rsquo;
                websites, services or links that you may access to via our
                Services.&nbsp; Information collected or received by these third
                parties is subject to third-party privacy policies.&nbsp; If you
                are located outside the United States, please note that personal
                information collected via our Services may be transferred to the
                United States and other countries which might not provide an
                equivalent level of protection as the data protection laws in
                your own country.
              </p>
              <p>
                This Privacy Policy is integrated into our
                <a href="https://www.acvauctions.com/legal">Terms of Service</a>
                and certain other terms of use which we may, from time to time,
                require you to accept as a condition of using our Services.
              </p>
              <h4>2. Information We Collect</h4>
              <p>
                ACV Auctions collects information about our Customers in three
                ways: (2.1) information you provide to us, (2.2) information
                collected through automated technologies, and (2.3) information
                collected from third parties.&nbsp; All of the information
                referred to in (2.1)-(2.3) are detailed below, and hereinafter
                referred to as &ldquo;Information.&rdquo;
              </p>
              <h4>2.1 Information You Provide to Us</h4>
              <p>
                In interacting with us and using our Services, you may provide
                us with Information, including, without limitation:
              </p>
              <ul>
                <li>
                  Contact details, such as your name, email address, phone
                  number, and physical address;
                </li>
                <li>
                  Account log in credentials, including unique identifiers such
                  as usernames, passwords and similar security information;
                </li>
                <li>
                  Other account registration and profile information, such as
                  the names, job titles and contact information for any other
                  individuals or employees associated with your account;
                </li>
                <li>Vehicle and inventory information;</li>
                <li>
                  Payment information, such as bank account and routing numbers;
                </li>
                <li>
                  Your social security number or driver&rsquo;s license
                  information;
                </li>
                <li>
                  Your geolocation data (including through our mobile app) and
                  other information you provide or allow us to access, including
                  data held on social media platforms, other software (for
                  example, access to your contacts, calendar or photos), and
                  information you send to customer support;
                </li>
                <li>
                  Additional information as otherwise described to you at the
                  point of collection or pursuant to your consent.
                </li>
              </ul>
              <h4>2.2 Information Collected Automatically</h4>
              <p>
                ACV Auctions may automatically collect, and associate with your
                account, certain Information when you access our Services using
                cookies, log files, web beacons, tags, or pixel tags, flash
                objects and other technologies.&nbsp; We collect this
                Information automatically when you access our website or use our
                mobile app, and we use this Information to improve the
                functionality and design of both our site and our app, and for
                advertising purposes.&nbsp; This Information may include,
                without limitation:
              </p>
              <ul>
                <li>
                  IP address, which is the number associated with the service
                  through which you access the Internet, like your ISP (internet
                  service provider);
                </li>
                <li>Date and time of your visit or use of our Services;</li>
                <li>Domain server from which you are using our Services;</li>
                <li>
                  Type of computer, web browsers, search engine used, operating
                  system, or platform you use;
                </li>
                <li>
                  Data identifying the web pages you visited prior to and after
                  visiting our website or use of our Services;
                </li>
                <li>
                  Your movement and activity within the website, which is
                  aggregated with other information;
                </li>
                <li>
                  Geolocation information through the use of any of our mobile
                  applications;
                </li>
                <li>
                  Mobile device information, including the type of device you
                  use, operating system version, and the device identifier (or
                  &ldquo;UDID&rdquo;);
                </li>
                <li>
                  Mobile application identification and behavior, use, and
                  aggregated usage, performance data, and where the application
                  was downloaded from.
                </li>
              </ul>
              <h4>2.3 Information We May Receive From Third Parties</h4>
              <p>
                ACV Auctions may also collect and receive Information about you
                from our business partners<span
                  style="text-decoration: line-through"
                >
                </span
                >, our dealer Customers, and other third parties.&nbsp; We
                collect this information to provide services to you and to
                improve the content and quality of the ACV Auctions Service.
                This Information may include, without limitation:
              </p>
              <ul>
                <li>
                  Information related to your vehicle purchases and sales, and
                  tax information;
                </li>
                <li>
                  For our dealer Customers, information related to your
                  dealership and your credit history;
                </li>
                <li>
                  Payment information, such as bank account routing numbers.
                </li>
              </ul>
              <p>
                Please keep in mind that when you provide Information to us via
                a third-party website or platform the Information you provide
                may be separately collected by the third-party website or the
                social media platform.
              </p>
              <p>
                The Information we collect is covered by this Privacy Policy,
                and the Information the third-party website or social media
                platform collects is subject to the third-party website or
                platform&rsquo;s privacy practices.&nbsp; We encourage you to be
                aware when you leave our sites or applications and to read the
                privacy policies of other sites that may collect
                your&nbsp;Information.
              </p>
              <h4>3. How We Use Your Information</h4>
              <p>
                ACV Auctions uses and processes Information that is collected
                for things that may include, but are not limited to, the
                following general purposes:
              </p>
              <ul>
                <li>
                  To provide, activate and manage your access to and use of our
                  Services;
                </li>
                <li>
                  To process your applications and related documents required to
                  become a contracted dealer and to make a decision regarding
                  the acceptance or rejection of your applications;
                </li>
                <li>
                  To verify the information that you provide to ACV Auctions;
                </li>
                <li>
                  To verify, evaluate, and/or monitor your creditworthiness;
                </li>
                <li>
                  To assist with the collection of any amounts owing by you to
                  ACV Auctions;
                </li>
                <li>
                  To process your payments to ACV Auctions (which, for the
                  removal of doubt, includes affiliates and subsidiaries, such
                  as ACV Capital LLC);
                </li>
                <li>
                  To respond to your any requests, inquiries, complaints, or;
                </li>
                <li>
                  To provide you with updates, announcements and other
                  personalized information regarding our Service;
                </li>
                <li>
                  To provide advertisements, notices and other information to
                  you through our website, email messages, mobile applications,
                  or other methods of communication;
                </li>
                <li>
                  To deliver targeted advertisements, promotional messages, and
                  other information about products, events and services of third
                  parties related to your interests;
                </li>
                <li>
                  To administer surveys, contests or other promotional
                  activities or events sponsored by us, our vendors or other
                  third-party service providers;
                </li>
                <li>
                  To help us to evaluate and improve our Services, including our
                  sales and marketing strategies and techniques, and to develop
                  new products and services;
                </li>
                <li>To operate and improve our website;</li>
                <li>
                  To provide third parties with information necessary to permit
                  that third party to conduct business with ACV Auctions and/or
                  effectively provide you with services;
                </li>
                <li>
                  To assist ACV Auctions and third parties in evaluating the
                  vehicle industry;
                </li>
                <li>
                  To comply with our legal obligations, resolve disputes, and
                  enforce our agreements;
                </li>
                <li>
                  For any other purposes disclosed to you at the time we collect
                  your Information and/or pursuant to your consent.
                </li>
              </ul>
              <h4>4. Sharing Your Information</h4>
              <p>
                ACV Auctions may share your Information to provide the products
                or services you have requested, when we have your permission,
                and under the following circumstances.
              </p>
              <h4>4.1 Our Affiliates and Service Providers</h4>
              <p>
                ACV Auctions may share your Information with service providers,
                third party auctions, and our agents and representatives to help
                us provide, improve, protect, and promote our Services to you.
                This may include, but is not limited to, payment processors and
                IT service providers. We require these third parties to process
                your Information in accordance with our instructions and in a
                manner consistent with this Privacy Policy.
              </p>
              <p>
                We may share your Information with subsidiaries, affiliates, and
                third parties, including sponsors and our dealer Customers, so
                they can provide services and advertisements of interest to
                you.&nbsp;
              </p>
              <h4>4.2&nbsp; Your Choices When Using the Service</h4>
              <p>
                ACV Auctions will display your profile Information, such as your
                name, images, and auction history, to other Customers utilizing
                ACV&rsquo;s Services.&nbsp; The Service may let you post and
                share additional information, comments, material and other
                content.&nbsp; Please exercise discretion when disclosing
                personal information in this manner.
              </p>
              <h4>4.3&nbsp;&nbsp;For Legal Reasons</h4>
              <p>
                We may disclose your Information to third parties if we
                determine that such disclosure is reasonably necessary to (a)
                investigate, prevent, or take action regarding suspected illegal
                activities or violations of our Terms of Service; (b) comply
                with any law, regulation or governmental request; (c) protect
                any person from death or serious bodily injury; (d) prevent
                fraud or abuse of ACV Auctions or our Customers; or (e) protect
                ACV Auctions&rsquo; property rights.
              </p>
              <p>
                We may share, sell, and/or transfer Information to a third party
                in the event of a potential or actual acquisition of ACV
                Auctions or its merger with another company.
              </p>
              <h4>5. Cookies</h4>
              <p>
                ACV Auctions also collects Information from and about the
                devices you use to access the Services.&nbsp; This includes
                things like IP addresses, the type of browser and device you
                use, and identifiers associated with your device(s).&nbsp;
                Customer devices, depending on their settings, may also transmit
                location Information to the Services.
              </p>
              <p>
                Cookies are small text files that store data using your web
                browser to make your browsing experience easier and
                faster.&nbsp; Cookies are required to use the ACV Auctions
                Service.&nbsp; We, and our third-party service providers who
                assist with managing our site, use cookies to record current
                session information.&nbsp; We may, from time to time, use
                similar technologies, including web beacons, pixel tags, flash
                objects, location tracking and/or third-party widgets for
                purposes of enhancing your web or mobile experience and/or for
                purposes of allowing you to share Information on another
                platform, such as a social media platform.&nbsp; ACV Auctions
                uses technologies like cookies to provide, improve, protect, and
                promote our Services.&nbsp; For example, cookies help ACV
                Auctions with things like remembering a Customer&rsquo;s
                preferred settings.&nbsp; By using our Service or by signing up
                for an account with us, you agree that we can place these
                cookies on your device.&nbsp; You may be able to adjust your
                browser settings to manage your cookie preferences. If you
                reject cookies, you may still use our Services, but the
                functionality of some areas may be limited.
              </p>
              <h4>6. Children&rsquo;s Privacy</h4>
              <p>
                ACV&rsquo;s Services are intended for use by an adult general
                audience.&nbsp; Our Services are not directed to children under
                the age of 13 and ACV Auctions does not knowingly solicit
                personal information from or market to children under the age of
                13.&nbsp; If we learn that anyone under the age of 13 has
                provided any personal information through our Services, we will
                use commercially reasonable efforts to promptly delete it.&nbsp;
                If you are a parent or guardian and believe your child has
                provided us with personal information, please contact us at the
                address below to request deletion.
              </p>
              <h4>
                7. Notice To California Residents&mdash;Your California Privacy
                Rights
              </h4>
              <p>
                The California Consumer Privacy Act (&ldquo;CCPA&rdquo;)
                provides California residents with specific rights regarding
                their personal information. This section of our Privacy Policy
                describes your CCPA rights and explains how to exercise those
                rights. It applies solely to California residents. Any term
                defined in the CCPA shall have the same meaning when used in
                this Section.
              </p>
              <h4>7.1 Information We Collect</h4>
              <table
                border="1"
                style="
    border-collapse: collapse;
    width: 96.7927%;
    height: 304px;
    border-color: #000000;
  "
              >
                <tbody>
                  <tr style="height: 16px">
                    <td style="width: 48.1657%; height: 16px">
                      <strong>Category</strong>
                    </td>
                    <td style="width: 48.1657%; height: 16px">
                      <strong>Examples</strong>
                    </td>
                  </tr>
                  <tr style="height: 64px">
                    <td style="width: 48.1657%; height: 64px">
                      A. Identifiers.
                    </td>
                    <td style="width: 48.1657%; height: 64px">
                      Name, billing and shipping addresses, unique personal
                      identifier, online identifier, Internet Protocol address,
                      email address, account name, Social Security number,
                      driver&rsquo;s license number, or other similar
                      identifiers.
                    </td>
                  </tr>
                  <tr style="height: 96px">
                    <td style="width: 48.1657%; height: 96px">
                      B. Personal information categories listed in the
                      California Customer Records statute (Cal. Civ. Code &sect;
                      1798.80(e)).
                    </td>
                    <td style="width: 48.1657%; height: 96px">
                      Name, signature, Social Security number, physical
                      characteristics or description, address, telephone number,
                      passport number, driver&rsquo;s license or state
                      identification card number, insurance policy number,
                      employment, employment history, bank account number,
                      credit card number, debit card number, or any other
                      financial information.
                    </td>
                  </tr>
                  <tr style="height: 48px">
                    <td style="width: 48.1657%; height: 48px">
                      C. Commercial information.
                    </td>
                    <td style="width: 48.1657%; height: 48px">
                      Records of personal property, products or services
                      purchased, obtained, or considered, or other purchasing or
                      consuming histories or tendencies.
                    </td>
                  </tr>
                  <tr style="height: 32px">
                    <td style="width: 48.1657%; height: 32px">
                      D. Internet or other similar network activity.
                    </td>
                    <td style="width: 48.1657%; height: 32px">
                      Browsing history, search history, information on a
                      consumer&rsquo;s interaction with a website, application,
                      or advertisement.
                    </td>
                  </tr>
                  <tr style="height: 16px">
                    <td style="width: 48.1657%; height: 16px">
                      E. Geolocation data.
                    </td>
                    <td style="width: 48.1657%; height: 16px">
                      Physical location or movements.
                    </td>
                  </tr>
                  <tr style="height: 16px">
                    <td style="width: 48.1657%; height: 16px">
                      F. Sensory data.
                    </td>
                    <td style="width: 48.1657%; height: 16px">
                      Audio, electronic, visual, thermal, olfactory, or similar
                      information.
                    </td>
                  </tr>
                  <tr style="height: 16px">
                    <td style="width: 48.1657%; height: 16px">
                      G. Professional or employment-related information.
                    </td>
                    <td style="width: 48.1657%; height: 16px">
                      Current or past job history
                    </td>
                  </tr>
                </tbody>
              </table>
              <p></p>
              <p>
                &nbsp;We obtain the categories of personal information listed
                above from the following categories of sources:
              </p>
              <ul>
                <li>
                  Directly and indirectly from you, our Customer, when using our
                  Services or visiting our website.
                </li>
                <li>
                  Directly and indirectly from our affiliates and subsidiaries,
                  to assist us in providing Services to you;
                </li>
                <li>Directly from our service providers;</li>
                <li>
                  Directly and indirectly from other third-parties, such as
                  advertisers, data brokers and social media platforms, who may
                  provide us data they have collected from or about you from
                  public records and private sources;
                </li>
                <li>
                  Directly from our Clients or their agents. For example, from
                  information our Clients provide to us related to the services
                  for which they engage us.
                </li>
              </ul>
              <h4>7.2&nbsp; Information Disclosed</h4>
              <p>
                In the preceding twelve (12) months, we have disclosed the
                following categories of personal information for a business
                purpose:
              </p>
              <ul>
                <li>Identifiers;</li>
                <li>
                  Personal information categories listed in the California
                  Customer Records statute;
                </li>
                <li>Commercial information;</li>
                <li>Internet or other similar network activity.</li>
              </ul>
              <p>
                We disclose your personal information for a business purpose to
                the following categories of third parties:
              </p>
              <ul>
                <li>Our affiliates and subsidiaries;</li>
                <li>Service providers;</li>
                <li>Advertisers;</li>
                <li>
                  Third parties to whom you or your agents authorize us to
                  disclose your personal information in connection with products
                  or services we provide to you.
                </li>
              </ul>
              <p>
                If you do not want us to share your personal information for
                advertising purposes, please email us at
                <a href="mailto:datasubjectrights@acvauctions.com"
                  >datasubjectrights@acvauctions.com</a
                >
                or call us toll free at 1 (855) 804-2858.
              </p>
              <h4>
                7.3&nbsp; Access to Specific Information and Data Portability
                Rights
              </h4>
              <p>
                You have the right to request that we disclose certain
                information to you about our collection and use of your personal
                information over the past 12 months. Once we receive and confirm
                your verifiable consumer request, we will disclose to you:
              </p>
              <ul>
                <li>
                  The categories of personal information we collected about you;
                </li>
                <li>
                  The categories of sources for the personal information we
                  collected about you;
                </li>
                <li>
                  Our business or commercial purpose for collecting that
                  personal information;
                </li>
                <li>
                  The categories of third parties with whom we share that
                  personal information;
                </li>
                <li>
                  The specific pieces of personal information we collected about
                  you (also called a data portability request);
                </li>
                <li>
                  If we sold or disclosed your personal information for a
                  business purpose:
                </li>
                <li>
                  For sales, the personal information categories purchased;
                </li>
                <li>
                  For disclosures for a business purpose, the personal
                  information categories disclosed.
                </li>
              </ul>
              <p></p>
              <h4>7.4 Deletion Request Rights</h4>
              <p>
                You have the right to request that we delete any of your
                personal information we collected from you and retained, subject
                to certain exceptions. Once we receive and confirm your
                verifiable consumer request, we will delete (and direct our
                service providers to delete) your personal information from our
                records, unless an exception applies.
              </p>
              <p>
                We may deny your deletion request if retaining the information
                is necessary for us or our service providers to:
              </p>
              <ul>
                <li>
                  Complete the transaction for which we collected the personal
                  information, provide a good or service requested by you, or
                  reasonably anticipated within the context of our ongoing
                  business relationship with you, or otherwise perform our
                  contract with you;
                </li>
                <li>
                  Detect security incidents, protect against malicious,
                  deceptive<span style="text-decoration: line-through"> </span>,
                  fraudulent, or illegal activity, and take all necessary and
                  appropriate steps to mitigate current and future risk;
                </li>
                <li>
                  Debug products to identify and repair errors that impair
                  existing intended functionality. debug and repair internal
                  information technology as necessary;
                </li>
                <li>
                  Undertake internal research for technological development and
                  demonstration;
                </li>
                <li>
                  Exercise free speech, ensure the right of another consumer to
                  exercise his or her free speech rights, or exercise another
                  right provided for by law;
                </li>
                <li>
                  Comply with the California Electronic Communications Privacy
                  Act;
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with
                  consumer expectations based on your relationship with us;
                </li>
                <li>Comply with a legal obligation;</li>
                <li>
                  Otherwise use your personal Information, internally, in a
                  lawful manner that is compatible with the context in which you
                  provided the Information.
                </li>
              </ul>
              <h4>
                7.5 Exercising Access, Data Portability, and Deletion Rights
              </h4>
              <p>
                To exercise the access, data portability, and deletion rights
                described above, please submit a verifiable consumer request to
                us by email, including your full name and ten-digit phone
                number, at&nbsp;<a
                  href="mailto:datasubjectrights@acvauctions.com"
                  >datasubjectrights@acvauctions.com</a
                >
                or call us toll free at 1 (855) 804-2858.
              </p>
              <p>
                Only you, or a person that you authorize to act on your behalf,
                may make a verifiable consumer request related to your personal
                Information.&nbsp; You may only make a verifiable consumer
                request for access or data portability twice within a 12-month
                period. The verifiable consumer request must:
              </p>
              <ul>
                <li>
                  Provide sufficient information that allows us to reasonably
                  verify you are the person about whom we collected personal
                  information or an authorized representative.
                </li>
                <li>
                  Describe your request with sufficient detail that allows us to
                  properly understand, evaluate, and respond to it.
                </li>
              </ul>
              <p>
                We will only use the personal information provided in a
                verifiable consumer request to verify the requestor&rsquo;s
                identity or authority to make the request. We cannot respond to
                your request or provide you with personal information if we
                cannot verify that the person making the request is the person
                about whom we collected the information, or someone authorized
                to act on such person&rsquo;s behalf. Making a verifiable
                consumer request does not require you to create an account with
                us.
              </p>
              <h4>7.6 Response Timing and Format</h4>
              <p>
                We endeavor to respond to a verifiable consumer request within
                45 days of its receipt.&nbsp; If we require more time (up to 90
                days), we will inform you of the reason and extension period in
                writing.&nbsp; If you have an account with us, we will deliver
                our written response to the registered email associated with the
                account.&nbsp; If you do not have an account with us, we will
                deliver our written response by mail or electronically, at your
                option. Any disclosures we provide will only cover the 12-month
                period preceding the verifiable consumer request&rsquo;s
                receipt.&nbsp; The response we provide will also explain the
                reasons we cannot comply with a request, if applicable. For data
                portability requests, we will select a format to provide your
                personal information that is readily usable and should allow you
                to transmit the information from one entity to another entity
                without hindrance.
              </p>
              <p>
                To the extent permitted by law, we may charge a reasonable fee
                to comply with your request.&nbsp; If we determine that the
                request warrants a fee, we will tell you why we made that
                decision and provide you with a cost estimate before completing
                your request.
              </p>
              <h4>7.7 Non-Discrimination</h4>
              <p>
                We may not, and will not, treat you differently or discriminate
                against you for exercising any of your CCPA rights. Unless
                permitted by the CCPA, we will not:
              </p>
              <ul>
                <li>Deny you goods or services;</li>
                <li>Charge different prices or rates for goods or services;</li>
                <li>
                  Provide you a different level or quality of goods or services;
                  or
                </li>
                <li>Suggest that any of the preceding will occur.</li>
              </ul>
              <p>
                However, we can and may charge you a different rate, or provide
                a different level of quality, if the difference is reasonably
                related to the value provided by your personal information. We
                may offer you certain financial incentives permitted by the CCPA
                that can result in different prices, rates, or quality levels.
              </p>
              <h4>7.8 Do Not Sell My Personal Information</h4>
              <p>
                The ACV Auctions app does not sell consumers&rsquo; personal
                information. However, ACV Auctions offers other products,
                including trade-in value estimators on third-party sites, that
                may collect and sell consumer personal information, under the
                CCPA.&nbsp; California residents who enter their information
                into such products may utilize the &ldquo;Do Not Sell My
                Personal Information&rdquo;
                <a
                  href="https://app.acvauctions.com/privacypolicy?_ga=2.189806891.1898181681.1626704935-1066631735.1617032132"
                  >webform</a
                >, email us at
                <a href="mailto:datasubjectrights@acvauctions.com"
                  >datasubjectrights@acvauctions.com</a
                >
                or call us toll free at 1 (855) 804-2858 to opt-out of the sale
                of certain personal information.
              </p>
              <h4>7.9&nbsp; Information</h4>
              <p>
                Statements pertaining to Section 7 are available in alternative
                formats upon request by emailing
                <a href="mailto:datasubjectrights@acvauctions.com"
                  >datasubjectrights@acvauctions.com</a
                >
                or calling us toll free at 1 (855) 804-2858.
              </p>
              <h4>8.&nbsp; Security</h4>
              <p>
                ACV Auctions uses a variety of commercially reasonable
                technical, physical, and administrative measures to protect the
                confidentiality, integrity, and security of Information. It is
                your responsibility to maintain the confidentiality of your
                password(s) and other user credentials.
              </p>
              <h4>9. Data Retention, Storage And Transmission</h4>
              <p>
                We will store your personal Information for as long as there is
                a business need for it in accordance with applicable record
                retention policies and a range of legal and regulatory
                recordkeeping requirements.
              </p>
              <p>
                To provide you with the Services, we may store, process, and
                transmit information in locations outside the United States.
              </p>
              <h4>10. Your Communications Preferences</h4>
              <p>
                You agree that we, or third-party service providers with whom we
                collaborate, may communicate with you regarding our Services via
                electronic messages, including email, text message, or mobile
                push notification to, for example, send you information relating
                to our products and Services, communicate with you about
                contests, sweepstakes, offers, promotions, rewards, upcoming
                events, and other news about products and services provided by
                or through us through permissible targeted advertisements
                offered by us, our parent companies, our subsidiaries, our
                affiliates, and other business partners.&nbsp; See Your Choices,
                below, for how you can update the way we contact you.
              </p>
              <h4>10.1 Your Choices</h4>
              <ul>
                <li>
                  <strong> </strong>By using our Services, you agree that we may
                  contact you by email as set forth herein.&nbsp; If you do not
                  want to receive marketing and promotional emails from us, you
                  may click on the &ldquo;unsubscribe&rdquo; link in the email
                  to unsubscribe and opt-out of marketing email communications
                  or contact us.
                </li>
                <li>
                  <strong>Mobile Push Notifications.&nbsp; </strong>By using our
                  Services, you agree that we may contact you by calling or
                  sending messages to your phone. You can use the settings on
                  your mobile device to enable or turn off mobile push
                  notifications from us or contact u
                </li>
                <li>
                  <strong>Text Messages.&nbsp; </strong>By using our Services,
                  you agree to be reached by text message. If you no longer want
                  to receive text messages from us,
                  reply&nbsp;<strong>STOP</strong>&nbsp;(or as otherwise
                  instructed) in the text message or contact u
                </li>
                <li>
                  <strong>Location Choices.&nbsp;</strong> You can change the
                  privacy settings of your device at any time to turn off the
                  sharing of location information with our Services. If you
                  choose to turn off location services, this could affect
                  certain features or services of our Services.
                </li>
                <li>
                  <strong>Opting Out of Direct Marketing.</strong> To exercise
                  choices regarding the marketing information you receive, you
                  may also review the following links:
                  <ul>
                    <li>
                      You may opt-out of tracking and receiving tailored
                      advertisements on your mobile device by some mobile
                      advertising companies and other similar entities by
                      downloading the App Choices app at
                      <a href="http://www.aboutads.info/appchoices"
                        >aboutads.info/appchoices</a
                      >.
                    </li>
                    <li>
                      You may opt-out of receiving permissible targeted
                      advertisements by using the&nbsp;NAI Opt-out tool
                      available at
                      <a href="http://optout.networkadvertising.org/?c=1"
                        >http://optout.networkadvertising.org/?c=1</a
                      >
                      or visiting About Ads at
                      <a href="http://optout.aboutads.info"
                        >http://optout.aboutads.info</a
                      >.
                    </li>
                    <li>
                      You can opt-out of having your activity on our Services
                      made available to Google Analytics by installing the
                      Google Analytics opt-out add-on for your web browser by
                      visiting:
                      <a href="https://tools.google.com/dlpage/gaoptout"
                        >https://tools.google.com/dlpage/gaoptout</a
                      >
                      for your web browser.
                    </li>
                  </ul>
                </li>
              </ul>
              <h4>11. Do Not Track</h4>
              <p>
                Please note that because there is no consistent industry
                understanding of how to respond to &ldquo;Do Not Track&rdquo;
                signals, we do not alter our data collection and usage practices
                when we detect such a signal from your browser.
              </p>
              <h4>12. Change Of Control</h4>
              <p>
                If we are involved in a reorganization, merger, acquisition or
                sale of our assets, your Information may be transferred as part
                of that deal.&nbsp; We will notify you (for example, via a
                message to the email address associated with your account) of
                any such deal and outline your choices in that event.
              </p>
              <h4>13. Changes To This Privacy Policy</h4>
              <p>
                We reserve the right to change, modify or amend this Privacy
                Policy at any time to reflect changes in our Services,
                accommodate new technologies, respond to regulatory
                requirements, or other purposes.&nbsp; If we modify our Privacy
                Policy, we will update the &ldquo;Effective Date&rdquo; and such
                changes will be effective upon posting.&nbsp; It is your
                obligation to check our Privacy Policy for any changes.
              </p>
              <h4>14. How To Contact Us</h4>
              <p>
                If you have any questions or requests regarding this Privacy
                Policy or our processing of your Information, please contact us
                at the following:
              </p>
              <div>Compliance &amp; Regulatory</div>
              <div>ACV Auctions Inc.</div>
              <div>640 Ellicott St.</div>
              <div>Suite 321</div>
              <div>Buffalo, NY 14203</div>
              <p></p>
              <p>
                Email:&nbsp;
                <a href="mailto:datasubjectrights@acvauctions.com"
                  >datasubjectrights@acvauctions.com</a
                >
              </p>
              <p>Toll Free: 1 (855) 804-2858</p>
              <p><strong>Effective Date:&nbsp; July 19, 2021</strong></p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
import Hero from "@/components/global/Hero";

export default {
  name: "LearnMore",
  components: {
    Hero
  },
  data() {
    return {
      visible: true
    };
  }
};
</script>
